//
// theme colors
//
$theme-colors: (
        'bg-white': (
                'list-color': darken($white, 30),
                'link-color': darken($white, 48),
                'link-hover': darken($white, 88),
                'hide-bg': darken($gray-100, 2),
                'hide-color': darken($white, 74),
                'hide-hover': darken($white, 88),
                'arrow-color': darken($white, 48),
                'arrow-hover': darken($white, 88),
                'icon-color': darken($white, 28),
                'icon-hover': darken($white, 88),
                'scrollbar-bg': darken($white, 16),
        ),
        'bg-light': (
                'list-color': darken($gray-100, 30),
                'link-color': darken($gray-100, 48),
                'link-hover': darken($gray-100, 88),
                'hide-bg': darken($gray-100, 2),
                'hide-color': darken($gray-100, 74),
                'hide-hover': darken($gray-100, 88),
                'arrow-color': darken($gray-100, 48),
                'arrow-hover': darken($gray-100, 88),
                'icon-color': darken($gray-100, 28),
                'icon-hover': darken($gray-100, 88),
                'scrollbar-bg': darken($gray-100, 16),
        ),
        'bg-dark': (
                'list-color': lighten($gray-800, 30),
                'link-color': lighten($gray-800, 48),
                'link-hover': lighten($gray-800, 88),
                'hide-bg': lighten($gray-800, 3),
                'hide-color': lighten($gray-800, 74),
                'hide-hover': lighten($gray-800, 88),
                'arrow-color': lighten($gray-800, 48),
                'arrow-hover': lighten($gray-800, 88),
                'icon-color': lighten($gray-800, 28),
                'icon-hover': lighten($gray-800, 88),
                'scrollbar-bg': lighten($gray-800, 26),
        ),
        'bg-blue': (
                'list-color': lighten($blue, 22),
                'link-color': lighten($blue, 42),
                'link-hover': lighten($blue, 88),
                'hide-bg': darken($blue, 5),
                'hide-color': lighten($blue, 90),
                'hide-hover': lighten($blue, 98),
                'arrow-color': lighten($blue, 48),
                'arrow-hover': lighten($blue, 88),
                'icon-color': lighten($blue, 28),
                'icon-hover': lighten($blue, 88),
                'scrollbar-bg': lighten($blue, 36),
        ),
        'bg-purple': (
                'list-color': lighten($purple, 22),
                'link-color': lighten($purple, 38),
                'link-hover': lighten($purple, 88),
                'hide-bg': darken($purple, 5),
                'hide-color': lighten($purple, 90),
                'hide-hover': lighten($purple, 98),
                'arrow-color': lighten($purple, 48),
                'arrow-hover': lighten($purple, 88),
                'icon-color': lighten($purple, 28),
                'icon-hover': lighten($purple, 88),
                'scrollbar-bg': lighten($purple, 36),
        ),
        'bg-pink': (
                'list-color': lighten($pink, 22),
                'link-color': lighten($pink, 36),
                'link-hover': lighten($pink, 88),
                'hide-bg': darken($pink, 5),
                'hide-color': lighten($pink, 90),
                'hide-hover': lighten($pink, 98),
                'arrow-color': lighten($pink, 48),
                'arrow-hover': lighten($pink, 88),
                'icon-color': lighten($pink, 28),
                'icon-hover': lighten($pink, 88),
                'scrollbar-bg': lighten($pink, 36),
        ),
        'bg-red': (
                'list-color': lighten($red, 22),
                'link-color': lighten($red, 38),
                'link-hover': lighten($red, 88),
                'hide-bg': darken($red, 5),
                'hide-color': lighten($red, 90),
                'hide-hover': lighten($red, 98),
                'arrow-color': lighten($red, 48),
                'arrow-hover': lighten($red, 88),
                'icon-color': lighten($red, 28),
                'icon-hover': lighten($red, 88),
                'scrollbar-bg': lighten($red, 36),
        ),
        'bg-orange': (
                'list-color': lighten($orange, 24),
                'link-color': lighten($orange, 38),
                'link-hover': lighten($orange, 88),
                'hide-bg': darken($orange, 5),
                'hide-color': lighten($orange, 90),
                'hide-hover': lighten($orange, 98),
                'arrow-color': lighten($orange, 48),
                'arrow-hover': lighten($orange, 88),
                'icon-color': lighten($orange, 28),
                'icon-hover': lighten($orange, 88),
                'scrollbar-bg': lighten($orange, 36),
        ),
        'bg-green': (
                'list-color': lighten($green, 22),
                'link-color': lighten($green, 50),
                'link-hover': lighten($green, 88),
                'hide-bg': darken($green, 3),
                'hide-color': lighten($green, 90),
                'hide-hover': lighten($green, 98),
                'arrow-color': lighten($green, 48),
                'arrow-hover': lighten($green, 88),
                'icon-color': lighten($green, 28),
                'icon-hover': lighten($green, 88),
                'scrollbar-bg': lighten($green, 52),
        ),
        'icon-blue': (
                'icon-hover': lighten($blue, 5),
        ),
        'icon-purple': (
                'icon-hover': lighten($purple, 5),
        ),
        'icon-pink': (
                'icon-hover': lighten($pink, 5),
        ),
        'icon-red': (
                'icon-hover': lighten($red, 5),
        ),
        'icon-orange': (
                'icon-hover': lighten($orange, 5),
        ),
        'icon-green': (
                'icon-hover': lighten($green, 5),
        ),
);
