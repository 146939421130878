//
// theme default
//

@each $theme, $map in $theme-colors {

  // list
  .list-#{$theme} {
    color: map-get($map, list-color);
  }

  // item list hyperlink
  .list-#{$theme} .list-link, {
    color: map-get($map, link-color);
  }

  .list-#{$theme} .link-current,
  .list-#{$theme} .list-link:hover,
  .list-#{$theme} .link-current:hover {
    color: map-get($map, link-hover);
  }

  // hidden list
  .list-#{$theme} .list-hidden {
    background-color: map-get($map, hide-bg);
  }

  .list-#{$theme} .list-hidden .list-link {
    color: map-get($map, hide-color);
    opacity: 0.6;
  }

  .list-#{$theme} .list-hidden .link-current,
  .list-#{$theme} .list-hidden .link-current:hover,
  .list-#{$theme} .list-hidden .list-link:hover {
    color: map-get($map, hide-hover);
    opacity: 1.0;
  }

  // arrow
  .list-#{$theme} .link-arrow:before {
    opacity: 0.3;
  }

  .list-#{$theme} .link-arrow.active:before {
    opacity: 1.0;
  }

  // icons
  .list-#{$theme} .list-link .fa,
  .list-#{$theme} .list-link .fas,
  .list-#{$theme} .list-link .far,
  .list-#{$theme} .list-link .fal,
  .list-#{$theme} .list-link .fad,
  .list-#{$theme} .list-link .fab {
    color: map-get($map, icon-color);
  }

  .list-#{$theme} .list-link.link-current .fa,
  .list-#{$theme} .list-link:hover .fa,

  .list-#{$theme} .list-link.link-current .fas,
  .list-#{$theme} .list-link:hover .fas,

  .list-#{$theme} .list-link.link-current .far,
  .list-#{$theme} .list-link:hover .far,

  .list-#{$theme} .list-link.link-current .fal,
  .list-#{$theme} .list-link:hover .fal,

  .list-#{$theme} .list-link.link-current .fad,
  .list-#{$theme} .list-link:hover .fad,

  .list-#{$theme} .list-link.link-current .fab,
  .list-#{$theme} .list-link:hover .fab {
    color: map-get($map, icon-hover);
  }

  // sidebar user
  .sidebar-user-#{$theme} {
    background-color: map-get($map, hide-bg);
  }

  .sidebar-user-#{$theme} .sidebar-user-link {
    color: map-get($map, link-color);
  }

  .sidebar-user-#{$theme} .sidebar-user-link:hover {
    color: map-get($map, link-hover);
  }

  // scrollbar
  .scrollbar-#{$theme} .ps__rail-y.ps--clicking .ps__thumb-y,
  .scrollbar-#{$theme} .ps__rail-y:focus .ps__thumb-y,
  .scrollbar-#{$theme} .ps__rail-y:hover .ps__thumb-y,
  .scrollbar-#{$theme} .ps__thumb-y {
    background-color: map-get($map, scrollbar-bg);
  }

  .ps .ps__rail-y.ps--clicking,
  .ps .ps__rail-y:focus,
  .ps .ps__rail-y:hover {
    background: none;
  }
}

// gradient background
.sidebar-bg-dark-gradient {
  background: rgb(52,58,64);
  background: linear-gradient(0, rgba(52,58,64,1) 0%, rgba(52,58,64,0.90) 100%);
}

.sidebar-bg-white-gradient {
  background: rgb(255,255,255);
  background: linear-gradient(0, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
}
