//
// nanobar component
//

.nanobar {
  width: 100%;
  height: $sm-nanobar;
  z-index: 9999;
  top: 0;

  & .bar {
    width: 0;
    height: 100%;
    transition: height 0.3s;
    background-color: $dark;
  }
}
