//
// list component for sidebar skeleton
//

.list {
  width: $sm-width;
  @include font-size($font-size);

  & &-hidden {
    display: none;
    overflow: hidden;
    padding: rem(10) 0 rem(10) rem(25);
  }

  &-hidden &-link {
    font-weight: 500;
    line-height: rem(30);
  }

  &-item {
    padding: 0;
    margin-top: rem(10);
    line-height: rem(36);
  }

  &-title {
    margin-bottom: 0;
    margin-left: rem(17);
    letter-spacing: rem(1);
    font-weight: 600;
    @include font-size($font-size-sm);
  }

  &-link {
    display: block;
    padding: 0 rem(10) 0 rem(19);
    margin: 0;
    font-weight: 600;
    @include hover {
      text-decoration: none;

      .fa,
      .fas,
      .far,
      .fal,
      .fad,
      .fab {
        animation: bellshake .5s cubic-bezier(.36,.07,.19,.97) both;
        backface-visibility: hidden;
        transform-origin: top right;
      }
    }
  }

  @keyframes bellshake {
    0% { transform: rotate(0); }
    15% { transform: rotate(5deg); }
    30% { transform: rotate(-5deg); }
    45% { transform: rotate(4deg); }
    60% { transform: rotate(-4deg); }
    75% { transform: rotate(2deg); }
    85% { transform: rotate(-2deg); }
    92% { transform: rotate(1deg); }
    100% { transform: rotate(0); }
  }

  &-icon {
    width: rem(25);
    display: inline-block;
  }
}

// arrow icon up and down in menu
.link-arrow:before {
  font-family: $font-awesome, sans-serif;
  font-weight: 900;
  margin-right: rem(5);
  float: right;
}

.link-arrow.up:before {
  content: "\f105";
}

.link-arrow.down:before {
  content: "\f107";
}

.link-arrow.transition:before {
  transition: 0.2s all;
}

.link-arrow.rotate:before {
  transform: rotate(90deg);
}

.link-arrow.rotate-revert:before {
  transform: rotate(-90deg);
}
