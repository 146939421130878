// font family
$font-sans: 'Open Sans', sans-serif;
$font-awesome: 'Font Awesome\ 5 Free';

// font size
$font-size: 14;
$font-size-sm: 12;

// other settings
$sm-scrollbar: rem(5);
$sm-nanobar: rem(2);
